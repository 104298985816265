import { HOOK_CORE_VAL } from "./actionType";

const initial_state = {
  core: {
    coreKey: -1,
    coreIp: "",
  },
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case HOOK_CORE_VAL:
      return {
        ...state,
        core: {
          coreKey: action?.key,
          coreIp: action?.ip,
        },
      };
    default:
      return { ...state };
  }
};
