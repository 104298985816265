import React, { useEffect, useRef, useState } from "react";
import { reqAt, StrUtil, setLoadStat, AlertM } from "@bizmk/bizmk-core-ui";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

const Desktop = () => {
  const dispatch = useDispatch();
  const mainRef = useRef(null);
  const myProfile = useRef(null);
  const mySkils = useRef(null);
  const myStorage = useRef(null);
  const myProjects = useRef(null);
  const [email, setEmail] = useState("");
  const rspHook = useSelector((content) => content?.RepApp?.rspHook);
  const rsp = useSelector((content) => content?.RepApp?.rsp);

  const scrollToArea = (ref) => {
    const element = ref.current;
    const offsetTop = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({
      top: offsetTop - 45,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (rsp) {
      if (_.isEqual(rsp.work, "send-mail")) {
        if (rsp.status == 200) {
          AlertM({
            title: "이메일",
            text: "이메일 발송 되었습니다.",
          });
          setLoadStat(false);
        } else {
          AlertM({
            title: "이메일",
            text: "이메일 발송이 실패 하였습니다.",
          });
          setLoadStat(false);
        }
      }
    }
  }, [rspHook]);
  return (
    <div className="main" ref={mainRef}>
      <div className="navigate">
        <button
          className="profile"
          onClick={() => scrollToArea(mainRef)}></button>
        <button onClick={() => scrollToArea(myProfile)}>Profile</button>
        <button onClick={() => scrollToArea(mySkils)}>Skills</button>
        <button onClick={() => scrollToArea(myStorage)}>Storage</button>
        <button onClick={() => scrollToArea(myProjects)}>Projects</button>
      </div>
      <div className="first">
        <div className="light"></div>
        <div className="name">Developer by Jaein Lee</div>
        <div className="serve">Portfolio</div>
        <div className="guideline"></div>
        <div className="mobile">
          <div className="dynamicIsland"></div>
          <div className="dynamicBottom"></div>
        </div>
      </div>
      <div className="gradation-1"></div>
      <div className="myProfile" ref={myProfile}>
        <div className="area">
          <div className="leftArea">
            <div className="title">Profile</div>
            <div className="detail">
              제 소개를 시작하겠습니다. 저는 『최소한의 노력으로 최대의 성과를
              이루자』라는 신념을 가지고 있습니다. 이를 통해 개발과 업무
              프로세스를 최대한 효율적으로 관리하기 위해 자동화 프로그래밍을
              하여 적극 활용합니다. 협업을 중시하며, 잘 모르는 분야나 부족한
              부분에 대해서도 빠르게 내용을 정리하여 필요한 지원 요청을 하며
              상대방이 도움이 필요할시 적극적으로 도움을 기여하고 있습니다.
              에러나 장애가 발생했을 때에는 철저한 분석을 통해 신속한 해결책을
              마련하며, 제가 관리하는 시스템에 문제가 있을 경우 신속히 수정하여
              운영이나 개발에 원활함을 도모하고 있습니다. 또한, 타 부서나 팀이
              관리하는 시스템에 문제가 발생하면 상세하고 충분한 설명과 로그를
              제공하여 협업 지원 요청을 합니다. 커뮤니케이션 역시 저에게 중요한
              가치로, 요구사항이나 요청사항을 명확히 정리하여 효율적으로 업무를
              수행하는 데에 집중하고 있습니다. 이로 인해 저는 효율적이고
              협력적인 개발자로서의 역량을 가진다고 자부합니다.
            </div>
            <div className="mailSend" style={{ marginBottom: "4vw" }}>
              <div className="title">기술이력서 발송</div>
              <div className="inputBox">
                <label>이메일 : </label>
                <input
                  type="text"
                  onChange={(v) => {
                    setEmail(v.target.value);
                  }}
                />
                <button
                  onClick={() => {
                    if (StrUtil.isEmail(email)) {
                      setLoadStat(true);
                      dispatch(
                        reqAt({
                          url: "/core/send-mail",
                          work: "send-mail",
                          mtd: "P",
                          email: email,
                        })
                      );
                    } else {
                      AlertM({
                        title: "이메일",
                        text: "이메일 형식이 아닙니다. 다시 입력해주세요.",
                      });
                    }
                  }}>
                  SEND
                </button>
              </div>
              <div className="detail">
                - 이메일 발송의 경우 postfix 서버를 사용하고 있으며 spf, dkim을
                사용하여 스팸 메일로 전환되지 않습니다.
              </div>
              <div className="detail">
                - 기술이력서 발송시 PORTFOLIO 작성자 본인에게도 발송된 메일
                주소가 메일로 전달 된다는 점 참고바랍니다.
              </div>
            </div>
          </div>
          <div className="line"></div>
          <div className="rightArea">
            <div className="question">Name</div>
            <div className="colLine"></div>
            <div className="answer">이재인</div>
            <div className="question">Birth</div>
            <div className="colLine"></div>
            <div className="answer">1991.07.21</div>
            <div className="question">Education</div>
            <div className="colLine"></div>
            <div className="answer">
              <strong>2010</strong> 서울 대원고등학교 졸업
            </div>
            <div className="answer">
              <strong>2015</strong> 동국대전산원 졸업
            </div>
            <div className="answer">
              <strong>2018.10 ~ 2019.07</strong> (주)미래능력기술연구원 교육
            </div>
            <div className="question">Participation Project History</div>
            <div className="colLine"></div>
            <div className="answer">
              <strong>23.02.01 ~ 00.00.00</strong> ERP 유지보수 (Posco Dx)
            </div>
            <div className="answer">
              <strong>22.09.01 ~ 22.12.31</strong> FPPS 개발 사업 (Sk Hynix)
            </div>
            <div className="answer">
              <strong>21.07.01 ~ 22.08.12</strong> Mecus, My-bot 유지보수
              (MetLife)
            </div>
            <div className="answer">
              <strong>21.02.01 ~ 21.06.30</strong> 모바일 홈페이지 고도화
              (흥국생명)
            </div>
            <div className="answer">
              <strong>20.01.01 ~ 21.01.31</strong> Biznaru System 운영 (KT)
            </div>
            <div className="answer">
              <strong>19.08.01 ~ 19.12.31</strong> 현대차 eUICC 고도화 (KT)
            </div>
          </div>
        </div>
      </div>
      <div className="gradation-2"></div>
      <div className="mySkills" ref={mySkils}>
        <div className="skils-title">Skils</div>
        <div className="front-skils">
          <span className="front-skils-title">Frontend</span>
          <div className="front-skils-image"></div>
        </div>
        <div className="back-skils">
          <span className="back-skils-title">Backend</span>
          <div className="back-skils-image"></div>
        </div>
        <div className="configuration-management-skils">
          <div
            className="configuration-management-skils-title"
            style={{ marginLeft: "-7vw" }}>
            Configuration
          </div>
          <div
            className="configuration-management-skils-title"
            style={{ marginLeft: "3vw" }}>
            Management
          </div>
          <div className="configuration-management-skils-image"></div>
        </div>
        <div className="side-skils">
          <div className="side-skils-title" style={{}}>
            CI/CD
          </div>
          <div
            className="side-skils-title"
            style={{ marginTop: "3vw", marginLeft: "-10vw" }}>
            & Others
          </div>
          <div className="side-skils-image"></div>
        </div>
      </div>
      <div className="gradation-3"></div>
      <div className="myStorage" ref={myStorage}>
        <span className="title">Storage</span>
        <div className="detail">
          <div className="area">
            <div className="input-area">
              <div className="area-title">
                <img src="https://git.bizmk.net/-/pwa-icons/logo-192.png"></img>
                <div>Gitlab</div>
              </div>
              <div className="area-detail">
                <div className="explanation">
                  개인 형상관리 하는 저장소 입니다. 과거의 개인 프로젝트 및 혼자
                  코딩 연습한 소스를 볼 수 있습니다. 하지만 운영 예정인 소스는
                  볼 수 없다는 점 참고 바랍니다.
                </div>
                URL :{" "}
                <a href="https://git.bizmk.net" target="_blank">
                  https://git.bizmk.net
                </a>
                <br />
                OPEN
                <br />
                <a href="https://marry.bizmk.net/" target="_blank">
                  결혼식 모바일 청첩장
                </a>
                <br />
                <a
                  href="https://git.bizmk.net/open/JAE-IN/bizmk-marry-ui"
                  target="_blank">
                  결혼식 모바일 청첩장 - UI
                </a>
                <br />
                <a
                  href="https://git.bizmk.net/open/JAE-IN/bizmk-marry-api"
                  target="_blank">
                  결혼식 모바일 청첩장 - API
                </a>
                <div class="not-open">
                  ※ 해당 홈페이지는 본인 결혼식때 사용하던 모바일 청첩장입니다.
                  <br />※ 인증서가 없으므로 시크릿모드에서 접속 가능합니다.
                </div>
                <br />
                <a href="https://bizmk.net/home" target="_blank">
                  포트폴리오 홈페이지
                </a>
                <br />
                <a
                  href="https://git.bizmk.net/open/JAE-IN/bizmk-portfolio-ui"
                  target="_blank">
                  포트폴리오 - UI
                </a>
                <br />
                <a
                  href="https://git.bizmk.net/open/JAE-IN/bimzk-portfolio-api"
                  target="_blank">
                  포트폴리오 - API
                </a>
                <br />
                <a
                  href="https://git.bizmk.net/open/JAE-IN/bizmk-stck"
                  target="_blank">
                  주식 주가 수집
                </a>
                <div class="not-open">
                  ※ 현재 미운영 중이며 과거에 사용하던 주식 주가 수집
                  웹프로그램.
                  <br />※ 해당 프로그램은 django 웹프레임워크로 구현되어
                  있습니다.
                </div>
              </div>
            </div>
          </div>
          <div className="area">
            <div className="input-area">
              <div className="area-title">
                <img src="https://verdaccio.org/img/logo/uk/verdaccio-tiny-uk-no-bg.svg"></img>
                <div>Verdaccio</div>
              </div>
              <div className="area-detail">
                <div className="explanation">
                  개인 NPM 저장소 입니다. 운영 예정 소스가 저장되어 있으며
                  공개할 수 없다는 점 참고 바랍니다.
                </div>
                URL :{" "}
                <a href="http://rpst.bizmk.net" target="_blank">
                  http://rpst.bizmk.net
                </a>
                <br />
                OPEN
                <br />
                <div className="not-open">
                  ※ 해당 저장소는 곧 운영될 예정 소스만 존재하여 공개할 수
                  없습니다.
                  <br />※ 인증서가 없으므로 시크릿모드에서 접속 가능합니다.
                </div>
              </div>
            </div>
          </div>
          <div className="area">
            <div className="input-area">
              <div className="area-title">
                <img src="https://www.sonatype.com/hubfs/1-2023%20New%20Site%20Assets/SONA%20Logos/sonatype-repository-icon.svg"></img>
                <div>Nexus</div>
              </div>
              <div className="area-detail">
                <div className="explanation">
                  개인 MAVEN 저장소 입니다. 운영 예정 소스가 저장되어 있으며
                  공개할 수 없다는 점 참고 바랍니다.
                </div>
                URL :{" "}
                <a href="http://nexus.bizmk.net" target="_blank">
                  http://nexus.bizmk.net
                </a>
                <br />
                OPEN
                <br />
                <div className="not-open">
                  ※ 해당 저장소는 곧 운영될 예정 소스만 존재하여 공개할 수
                  없습니다.
                  <br />※ 인증서가 없으므로 시크릿모드에서 접속 가능합니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gradation-4"></div>
      <div className="myProjects" ref={myProjects}>
        <div className="title">Projects</div>
        <div className="area">
          <div className="main-detail">
            <strong>23.02.01 ~ 00.00.00</strong> ERP 유지보수 (Posco Dx)
          </div>
          <div className="sub-detail">
            {" "}
            - 업무내용 : POSCO, POSCO HOLDINGS 관련 경영지원 홍보 WEB 및 APP
            (WEB VIEW) 유지보수 하고 있으며 7개의 홈페이지를 관리하고 있습니다.
          </div>
          <div className="sub-detail margin-bottom">
            {" "}
            - 환경 : Html, Css, Javascript, Jsp, Jstl, bootstrap, Jquery, Ajax,
            Glue, Struts, Spring, Oracle
          </div>
          <div className="main-detail">
            <strong>22.09.01 ~ 22.12.31</strong> FPPS 개발 사업 (Sk Hynix)
          </div>
          <div className="sub-detail">
            {" "}
            - 업무내용 : FPPS (Frame Photo Portal System) 개발 프로젝트로
            하드웨어의 사진을 분석하여 불량 체크하는 시스템 개발
            프로젝트이였습니다. 해당 프로젝트에서 하드웨어 관리 WEB 개발을
            하였습니다.
          </div>
          <div className="sub-detail margin-bottom">
            {" "}
            - 환경 : Html, Css, Javascript, React, Redux, Spring boot, Oracle
          </div>
          <div className="main-detail">
            <strong>21.07.01 ~ 22.08.12</strong> Mecus, My-bot 유지보수
            (MetLife)
          </div>
          <div className="sub-detail">
            {" "}
            - 업무내용 : Mecus는 콜센터 System이며 My-bot은 kakaotalk을 통한
            24시간 안내 및 지급, 수금 관리하는 System 입니다. Mecus의 경우 고객
            파트를 WEB 유지보수 하였으며 My-bot은 kakao open builder 부터 WEB
            모든 유지보수 하였습니다.
          </div>
          <div className="sub-detail margin-bottom">
            {" "}
            - 환경 : html, css, javascript, Jquery, ncrm, db2, python, flask,
            asynico, sanic, MySql
          </div>
          <div className="main-detail">
            <strong>21.02.01 ~ 21.06.30</strong> 모바일 홈페이지 고도화
            (흥국생명)
          </div>
          <div className="sub-detail">
            {" "}
            - 업무내용 : 모바일 홈페이지 고도화 프로젝트로 해당 시스템에서 보상
            관련하여 모바일 WEB 개발을 하였습니다.
          </div>
          <div className="sub-detail margin-bottom">
            {" "}
            - 환경 : html, css, javascript, Jquery, Ajax, Spring, Oracle
          </div>
          <div className="main-detail">
            <strong>20.01.01 ~ 21.01.31</strong> Biznaru System 운영 (KT)
          </div>
          <div className="sub-detail">
            {" "}
            - 업무내용 : Biznaru는 KT 내부 결제 시스템 중 하나 이며 DML,DDL 처리
            및 배포, 오류 추척 등 관련된 운영을 하였습니다.
          </div>
          <div className="sub-detail margin-bottom">
            {" "}
            - 환경 : html, css, javascript, Jquery, Ajax, Spring, AltibaseDb
          </div>
          <div className="main-detail">
            <strong>19.08.01 ~ 19.12.31</strong> 현대차 eUICC 고도화 (KT)
          </div>
          <div className="sub-detail">
            {" "}
            - 업무내용 : 현대차 eUICC 프로젝트는 IOT 사업으로 현대차에 들어가는
            내장형 SIM을 원격으로 관리하고 전환하는 시스템 구축 프로젝트입니다.
            해당 프로젝트 당시에는 CRM WEB 시스템을 고도화하는 담당을
            하였습니다.
          </div>
          <div className="sub-detail margin-bottom">
            {" "}
            - 환경 : html, css, javascript, bootstrap, AngularJS, RequireJS,
            Spring, postgresql
          </div>
          <div className="shiba-inu-body">
            <div className="shiba-inu-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Desktop };
