import {
  BasicRoutes,
  reqAt,
  Error500,
  Error404,
  Error403,
  Error400,
  Dvc,
  hookMvPath,
  StrUtil,
} from "@bizmk/bizmk-core-ui";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import { Desktop } from "../pg/desktop";
import { Mobile } from "../pg/mobile";
import _ from "lodash";

const Routes = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [lst, setLst] = useState([]);
  const [home, setHome] = useState("");
  const rspHook = useSelector((content) => content?.RepApp?.rspHook);
  const rsp = useSelector((content) => content?.RepApp?.rsp);
  //setting
  // hook

  useEffect(() => {
    if (rsp) {
      const pgList = {
        PATH_000001: <Mobile />,
        PATH_000002: <Desktop />,
        PATH_000003: <Error404 />,
        PATH_000004: <Error500 />,
        PATH_000005: <Error403 />,
        PATH_000006: <Error400 />,
      };
      if (_.isEqual(rsp?.work, "path-select")) {
        if (rsp.status == 200) {
          if (!StrUtil.isEmpty(rsp.data.selectPathInformation)) {
            setLst([
              ...rsp.data.selectPathInformation.map((v) => {
                if (
                  _.isEqual(Dvc.isDvc(), "E") &&
                  _.isEqual(v?.KEY, "PATH_000002")
                ) {
                  setHome(v.PATH);
                } else if (
                  !_.isEqual(Dvc.isDvc(), "E") &&
                  _.isEqual(v?.KEY, "PATH_000001")
                ) {
                  setHome(v.PATH);
                }
                return <Route exact path={v.PATH} element={pgList[v.KEY]} />;
              }),
            ]);
          }
        }
      }
    }
  }, [rspHook]);

  useEffect(() => {
    if (lst.length > 0) {
      if (!_.isEqual(["/home"].indexOf(location?.pathname), -1)) {
        dispatch(hookMvPath(home));
      }
    }
  }, [location?.pathname, lst]);

  useEffect(() => {
    dispatch(reqAt({ url: "/core/initialize-path", work: "path-select" }));
  }, []);

  return (
    <>
      <BasicRoutes>
        {lst.map((v) => {
          return v;
        })}
        <Route exact path={`/`} element={<Error404 />} />,
        <Route exact path={`*`} element={<Error404 />} />,
      </BasicRoutes>
    </>
  );
};

export { Routes };
