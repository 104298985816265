import React from "react";
import ReactDOM from "react-dom";
import { Routes } from "./routes";
import { BasicBase, BasicHd } from "@bizmk/bizmk-core-ui";
import store from "./store";
import "./index.scss";
import "@bizmk/bizmk-core-ui/main/index.scss";
const Root = () => {
  return (
    <BasicBase store={store}>
      <BasicHd>
        <Routes></Routes>
      </BasicHd>
    </BasicBase>
  );
};

const rootElement = document.getElementById("root");
ReactDOM.render(<Root />, rootElement);
