import { combineReducers } from "redux";
import CoreApp from "./core-app/reducer";
import { basicReducers } from "@bizmk/bizmk-core-ui";

const reducers = combineReducers({
  CoreApp,
  ...basicReducers(),
});

export default reducers;
